import { useTranslation } from 'react-i18next';
import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import styles from './styles.module.scss';

export const About = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.root}>
      <ParallaxImage src="balls/47" className={styles.ball1} />
      <ParallaxImage src="balls/31" className={styles.ball2} />
      <Container className={styles.container}>
        <div className={styles.left}>
          <Typography
            className={styles.title}
            textStyle="h1"
            yellowGradient
            tag="h2"
          >
            {t('about.title')}
          </Typography>
          <Typography className={styles.subtitle} textStyle="h2" tag="h3">
            {t('about.description')}
          </Typography>
          <div className={styles.text}>
            <Typography className={styles.desc} textStyle="p" tag="p">
              {t('about.text_1')}
            </Typography>
            <Typography className={styles.desc} textStyle="p" tag="p">
              {t('about.text_2')}
            </Typography>
          </div>
          <div className={styles.badgeContainer}>
            <img
              className={styles.badge}
              src="/images/bage.png"
              alt="Big win"
            />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img
            src="/images/play-table.svg"
            className={styles.image}
            alt={t('about.title')}
          />
        </div>
        <div className={styles.balls}>
          <ParallaxImage src="balls/31" className={styles.ball3} />
          <ParallaxImage src="balls/18" className={styles.ball4} />
          <ParallaxImage src="balls/36" className={styles.ball5} />
          <ParallaxImage src="balls/74" className={styles.ball6} />
        </div>
      </Container>
    </Section>
  );
};
