import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { LangPicker } from '@/shared/ui/lang-picker';
import { Logo } from '@/shared/ui/logo';
import { Social } from '@/shared/ui/social';
import styles from './styles.module.scss';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.root}>
      <Container className={styles.container}>
        <Logo className={styles.logo} />
        <div className={styles.right}>
          <LangPicker openDirection="top" />
          <div className={styles.socialContainer}>
            <span className={styles.title}>{t('social_title')}</span>
            <Social className={styles.social} />
          </div>
        </div>
      </Container>
    </footer>
  );
};
