import type { TFunction } from 'i18next';

export const launchContent = (t: TFunction) => [
  {
    direction: 'row',
    image: '/images/launch/1.png',
    title: t('launch.launch_1.title'),
    description: t('launch.launch_1.description'),
  },
  {
    direction: 'row-reverse',
    image: '/images/launch/2.png',
    title: t('launch.launch_2.title'),
    description: t('launch.launch_2.description'),
  },
];
