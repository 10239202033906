import { LangPicker } from '@/shared/ui/lang-picker';
import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import styles from './styles.module.scss';

export const Header = () => {
  return (
    <header className={styles.root}>
      <Container className={styles.container}>
        <Logo />
        <LangPicker />
      </Container>
    </header>
  );
};
