import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icon } from '@/shared/ui/icon';
import styles from './styles.module.scss';

interface LangPickerProps {
  openDirection?: 'top' | 'bottom';
}

export const LangPicker = ({ openDirection = 'bottom' }: LangPickerProps) => {
  const { i18n } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [currentLanguage, ...languages] = i18n.languages;

  const onLangSelectorClick = () => {
    setIsOpened((prevState) => !prevState);
  };

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('locale', lang);
    setIsOpened(false);
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.current}
        onClick={onLangSelectorClick}
        onBlur={() => setIsOpened(false)}
        tabIndex={0}
        role="button"
      >
        <div className={styles.name}>{i18n.language}</div>
        <Icon className={styles.arrow} name="common/arrow" />
      </div>
      <ul
        hidden={!isOpened}
        className={clsx(
          styles.dropdown,
          openDirection === 'bottom' ? styles.bottom : styles.top,
        )}
      >
        {languages.map((lang) => (
          <li
            role="presentation"
            key={lang}
            onMouseDown={() => onChangeLanguage(lang)}
            className={styles.dropdownItem}
          >
            <span className={styles.name}>{lang}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
