import { useTranslation } from 'react-i18next';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { Section } from '@/shared/ui/section';
import { ToggleSocial } from '@/features/toggle-social';
import styles from './styles.module.scss';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.root}>
      <div className={styles.wrapper}>
        <ParallaxImage
          paralaxFactor={0.015}
          src="confetti/1"
          className={styles.confetti1}
        />
        <ParallaxImage
          allowParalax={false}
          src="confetti/2"
          className={styles.confetti2}
        />
        <ParallaxImage
          paralaxFactor={0.015}
          src="confetti/3"
          className={styles.confetti3}
        />
        <Container className={styles.container}>
          <img
            width={1440}
            height={840}
            className={styles.image}
            src="/images/hero-image.png"
            alt="Хитовая лотерея,  созданная специально для игровых залов"
          />
          <Typography textStyle="h1" className={styles.title} tag="h1">
            {t('hero.title')}
          </Typography>
          <Typography yellowGradient className={styles.description} tag="h2">
            {t('hero.description')}
          </Typography>
          <ToggleSocial className={styles.btn} />
        </Container>
        <ParallaxImage
          paralaxFactor={0.02}
          src="balls/34"
          className={styles.ball1}
        />
        <ParallaxImage
          paralaxFactor={-0.015}
          src="balls/72"
          className={styles.ball2}
        />
        <ParallaxImage
          paralaxFactor={-0.025}
          src="balls/18"
          className={styles.ball3}
        />
        <ParallaxImage
          paralaxFactor={0.02}
          src="balls/74"
          className={styles.ball4}
        />
        <ParallaxImage
          paralaxFactor={0.02}
          src="balls/36"
          className={styles.ball5}
        />
        <ParallaxImage
          paralaxFactor={0.02}
          src="balls/27"
          className={styles.ball6}
        />
        <ParallaxImage
          paralaxFactor={0.02}
          src="balls/42"
          className={styles.ball7}
        />
        <ParallaxImage
          paralaxFactor={-0.03}
          src="balls/11"
          className={styles.ball8}
        />
      </div>
    </Section>
  );
};
