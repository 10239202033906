import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { languagesItems } from './languages.config';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import styles from './styles.module.scss';

export const Languages = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.root}>
      <ParallaxImage src="balls/14" className={styles.ball1} />
      <Container className={styles.container}>
        <Typography
          className={styles.title}
          textStyle="h1"
          tag="h2"
          dangerouslySetInnerHTML={{ __html: t('languages.title') }}
        />
        <ul className={styles.list}>
          {languagesItems.map((item) => {
            const Icon = item.icon;

            return (
              <li className={styles.item} key={item.text}>
                <div className={styles.icon}>
                  <Icon />
                </div>
                <span className={styles.text}>{item.text}</span>
              </li>
            );
          })}
        </ul>
      </Container>
    </Section>
  );
};
