import { useState, type HTMLAttributes } from 'react';
import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';
import { Social } from '@/shared/ui/social';
import { Icon } from '@/shared/ui/icon';
import styles from './styles.module.scss';

type ToggleSocialProps = HTMLAttributes<HTMLDivElement>;

export const ToggleSocial = ({ className }: ToggleSocialProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <div hidden={!isOpened} className={styles.row}>
        <Social />
        <button className={styles.close} onClick={() => setIsOpened(false)}>
          <Icon name="common/cross" />
        </button>
      </div>
      <Button
        hidden={isOpened}
        onClick={() => setIsOpened(true)}
        className={className}
      >
        {t('contact_btn')}
      </Button>
    </>
  );
};
