import type { TFunction } from 'i18next';

export const benefitsItems = (t: TFunction) => [
  {
    image: '/images/benefits/1.png',
    title: t('benefits.item_1.title'),
    items: [t('benefits.item_1.benefit_1'), t('benefits.item_1.benefit_2')],
  },
  {
    image: '/images/benefits/2.png',
    title: t('benefits.item_2.title'),
    items: [t('benefits.item_2.benefit_1'), t('benefits.item_2.benefit_2')],
  },
  {
    image: '/images/benefits/3.png',
    title: t('benefits.item_3.title'),
    items: [
      t('benefits.item_3.benefit_1'),
      t('benefits.item_3.benefit_2'),
      t('benefits.item_3.benefit_3'),
    ],
  },
  {
    image: '/images/benefits/4.png',
    title: t('benefits.item_4.title'),
    items: [t('benefits.item_4.benefit_1'), t('benefits.item_4.benefit_2')],
  },
];
