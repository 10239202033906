import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { gamesCards } from './games.config';
import { Button } from '@/shared/ui/button';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import styles from './styles.module.scss';

export const Games = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.root}>
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/8"
        className={styles.ball1}
      />
      <Container className={styles.container}>
        <Typography
          className={styles.title}
          textStyle="h1"
          tag="h2"
          dangerouslySetInnerHTML={{ __html: t('games.title') }}
        />
        <div className={styles.cards}>
          {gamesCards.map((card) => (
            <div key={card.buttonText} className={styles.card}>
              <div className={styles.image}>
                <img src={card.image} alt={card.buttonText} />
              </div>
              <Button
                href={card.href}
                target="_blank"
                as="a"
                className={styles.btn}
              >
                {card.buttonText}
              </Button>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};
