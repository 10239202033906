export const gamesCards = [
  {
    image: '/images/games/1.jpg',
    href: 'https://clickobet.com/',
    buttonText: 'PILOT TV',
  },
  {
    image: '/images/games/2.jpg',
    href: 'https://www.winwheel.net/',
    buttonText: 'Win Wheel',
  },
];
