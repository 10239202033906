import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from '@/widgets/header';
import { Hero } from '@/widgets/hero';
import { About } from '@/widgets/about';
import { Peculiarities } from '@/widgets/peculiarities';
import { Benefits } from '@/widgets/benefits';
import { Languages } from '@/widgets/languages';
import { Launch } from '@/widgets/launch';
import { Games } from '@/widgets/games';
import { Footer } from '@/widgets/footer';
import { ScrollProvider } from '@/shared/context/scroll-context';
import { useTranslation } from 'react-i18next';

export const App = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollProvider>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
        </Helmet>
        <Header />
        <main>
          <Hero />
          <About />
          <Peculiarities />
          <Benefits />
          <Languages />
          <Launch />
          <Games />
        </main>
        <Footer />
      </ScrollProvider>
    </HelmetProvider>
  );
};
