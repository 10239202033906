import useEmblaCarousel from 'embla-carousel-react';
import { useTranslation } from 'react-i18next';
import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { Icon } from '@/shared/ui/icon';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { slidesContent } from './peculiarities.config';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const Peculiarities = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
  });

  const { t } = useTranslation();

  const items = slidesContent(t);

  return (
    <Section className={styles.root}>
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/14"
        className={styles.ball1}
      />
      <ParallaxImage src="balls/4" className={styles.ball2} />
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/47"
        className={styles.ball3}
      />
      <ParallaxImage src="confetti/4" className={styles.confetti1} />
      <ParallaxImage
        paralaxFactor={0.025}
        src="confetti/5"
        className={styles.confetti2}
      />
      <Container className={styles.container}>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('peculiarities.title') }}
          textStyle="h1"
          className={styles.title}
          tag="h2"
        />
        <div className={styles.carousel}>
          <div className={styles.carouselWrapper}>
            <div className={styles.carouselViewport} ref={emblaRef}>
              <div className={styles.carouselContainer}>
                {items.map((slide) => (
                  <div key={slide.description} className={styles.slide}>
                    <Typography className={styles.desc} textStyle="p" tag="p">
                      {slide.description}
                    </Typography>
                    <div className={styles.slideContainer}>
                      <picture>
                        <source
                          media="(max-width: 576px)"
                          srcSet="/images/peculiarities/mobile-bg.png"
                        />
                        <ParallaxImage
                          src="peculiarities/desktop-bg"
                          className={styles.bg}
                        />
                      </picture>
                      <div className={styles.imageContainer}>
                        <img
                          className={clsx(styles.image, styles.tv)}
                          src="/images/tv.png"
                          alt={t('peculiarities.title')}
                        />
                        <img
                          className={clsx(styles.image, styles.mainImage)}
                          src={slide.image}
                          alt={t('peculiarities.title')}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.btns}>
            <button
              className={styles.prev}
              onClick={() => emblaApi?.scrollPrev()}
            >
              <Icon className={styles.arrow} name="common/arrow" />
            </button>
            <button
              className={styles.next}
              onClick={() => emblaApi?.scrollNext()}
            >
              <Icon className={styles.arrow} name="common/arrow" />
            </button>
          </div>
        </div>
      </Container>
    </Section>
  );
};
