import { ReactComponent as FrIcon } from './assets/fr.svg';
import { ReactComponent as DeIcon } from './assets/de.svg';
import { ReactComponent as RuIcon } from './assets/ru.svg';
import { ReactComponent as EnIcon } from './assets/en.svg';
import { ReactComponent as UaIcon } from './assets/ua.svg';
import { ReactComponent as EsIcon } from './assets/es.svg';
import { ReactComponent as PtIcon } from './assets/pt.svg';

export const languagesItems = [
  { icon: FrIcon, text: 'fr' },
  { icon: DeIcon, text: 'de' },
  { icon: RuIcon, text: 'ru' },
  { icon: EnIcon, text: 'en' },
  { icon: UaIcon, text: 'ua' },
  { icon: EsIcon, text: 'es' },
  { icon: PtIcon, text: 'pt' },
];
