import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { launchContent } from './launch.config';
import type { CSSProperties } from 'react';
import clsx from 'clsx';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { ToggleSocial } from '@/features/toggle-social';
import styles from './styles.module.scss';

export const Launch = () => {
  const { t } = useTranslation();

  const items = launchContent(t);

  return (
    <Section className={styles.root}>
      <ParallaxImage src="balls/8" className={styles.ball1} />
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/56"
        className={styles.ball2}
      />
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/39"
        className={styles.ball3}
      />
      <ParallaxImage src="balls/71" className={styles.ball4} />
      <ParallaxImage src="balls/39" className={styles.ball5} />
      <ParallaxImage src="balls/77" className={styles.ball6} />
      <ParallaxImage
        paralaxFactor={-0.015}
        src="balls/14"
        className={styles.ball7}
      />
      <Container className={styles.container}>
        <Typography
          className={styles.title}
          textStyle="h1"
          tag="h2"
          dangerouslySetInnerHTML={{ __html: t('launch.title') }}
        />
        <div className={styles.items}>
          {items.map((item, idx) => (
            <div
              style={{ '--direction': item.direction } as CSSProperties}
              key={item.title}
              className={styles.item}
            >
              <div className={clsx(styles.image, styles[`image-${idx + 1}`])}>
                <img src={item.image} alt={item.title} />
              </div>
              <div className={styles.text}>
                <Typography
                  textStyle="h3"
                  tag="h3"
                  className={styles.itemTitle}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <Typography
                  textStyle="p"
                  tag="p"
                  className={styles.itemDesc}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </div>
        <ToggleSocial className={styles.btn} />
      </Container>
    </Section>
  );
};
