import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface ButtonProps extends PropsWithChildren {
  as?: 'button' | 'a';
  href?: string;
  target?: string;
  className?: string;
  hidden?: boolean;
  onClick?: () => void;
}

export const Button = ({
  className,
  as = 'button',
  children,
  ...props
}: ButtonProps) => {
  const Element = as;

  return (
    <Element className={clsx(styles.btn, className)} {...props}>
      <span aria-hidden className={styles.bg} />
      {children}
    </Element>
  );
};
