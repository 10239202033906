import type { TFunction } from 'i18next';

export const slidesContent = (t: TFunction) => [
  {
    description: t('peculiarities.description1'),
    image: '/images/peculiarities/1.png',
  },
  {
    description: t('peculiarities.description2'),
    image: '/images/peculiarities/2.png',
  },
  {
    description: t('peculiarities.description3'),
    image: '/images/peculiarities/3.png',
  },
  {
    description: t('peculiarities.description4'),
    image: '/images/peculiarities/4.png',
  },
];
