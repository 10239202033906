import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/app/assets/locales/en.json';
import ru from '@/app/assets/locales/ru.json';
import es from '@/app/assets/locales/es.json';

const i18nInstance = use(initReactI18next);

const locales = { en, ru, es };

i18nInstance.init({
  resources: locales,
  lng: localStorage.getItem('locale') || 'en',
  fallbackLng: Object.keys(locales),
});
