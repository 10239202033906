import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { benefitsItems } from './benefits.config';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { ToggleSocial } from '@/features/toggle-social';
import styles from './styles.module.scss';

export const Benefits = () => {
  const { t } = useTranslation();

  const items = benefitsItems(t);

  return (
    <Section className={styles.root}>
      <ParallaxImage src="balls/39" className={styles.ball1} />
      <ParallaxImage src="balls/69" className={styles.ball2} />
      <ParallaxImage src="balls/25" className={styles.ball3} />
      <ParallaxImage src="balls/71" className={styles.ball4} />
      <ParallaxImage src="balls/39" className={styles.ball5} />
      <ParallaxImage src="balls/77" className={styles.ball6} />
      <Container className={styles.container}>
        <Typography
          className={styles.title}
          textStyle="h1"
          tag="h2"
          dangerouslySetInnerHTML={{ __html: t('benefits.title') }}
        />
        <ul className={styles.benefits}>
          {items.map((item) => (
            <li key={item.image} className={styles.benefit}>
              <img className={styles.image} src={item.image} alt={item.title} />
              <div className={styles.text}>
                <Typography
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  textStyle="h3"
                  tag="h3"
                />
                <ul className={styles.list}>
                  {item.items.map((item) => (
                    <li className={styles.item} key={item}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <ToggleSocial className={styles.btn} />
      </Container>
    </Section>
  );
};
